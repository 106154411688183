import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n  .hydroboots .faq__item__name { color: #77787b; }\n  .hydroboots .faq__item__content { color:#77787b; }\n  .hydroboots .faq__item__short .btn__more { background-color: #cfddf2; }\n  .hydroboots .faq__item__short .btn__less { background-color: #cfddf2; }\n"
          }}
        />
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="section">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Pielęgnacja twarzy</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/bad-piekna/">Bądź piękna</a> <span>&gt;</span>
                  <a href="/site/bad-piekna/pielegnacja-twarzy/">
                    Pielęgnacja twarzy
                  </a>{" "}
                  <span>&gt;</span>
                  Pielęgnacja twarzy
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/site/bad-piekna/test-skory/" className="link">
                Test skóry
              </a>
              <a
                href="/site/bad-piekna/ogolnopolskie-badanie-skory/"
                className="link"
              >
                Badanie Skóry
              </a>
              <a href="/site/bad-piekna/sucha-skora/" className="link">
                Sucha skóra
              </a>
              <a href="/site/bad-piekna/pielegnacja-twarzy/" className="link">
                Pielęgnacja twarzy
              </a>
              <a href="/site/bad-piekna/pielegnacja-ust/" className="link">
                Pielęgnacja ust
              </a>
              <a href="/site/bad-piekna/pielegnacja-stop/" className="link">
                Pielęgnacja stóp
              </a>
              <a href="/site/bad-piekna/pielegnacja-ciala/" className="link">
                Pielęgnacja ciała i regeneracja skóry
              </a>
              <a href="/site/bad-piekna/pielegnacja-doni/" className="link">
                Pielęgnacja dłoni
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="text full">
                  <div className="p">
                    <div
                      className="column left"
                      style={{
                        width: "520px"
                      }}
                    >
                      <h2>
                        Czy wiesz jak chronić skórę przed szkodliwymi skutkami
                        smogu?
                      </h2>
                      <p>
                        Nie ulega wątpliwości, że powietrze którym oddychamy w
                        dzisiejszych czasach jest złej jakości. Oprócz licznych
                        zanieczyszczeń i spalin jesteśmy narażeni na nowe
                        zjawisko występujące pod nazwą "smog". Jest to nic
                        innego jak połączenie dwóch słów z języka angielskiego
                        smoke - dym i fog - mgła. Smog działa silnie
                        destrukcyjnie na organizm i nie bez przyczyny od
                        niedawna dyskutuje o nim cały świat. Zjawisko smogu
                        oznacza współistnienie zanieczyszczenia powietrza oraz
                        zjawisk atmosferycznych, takich jak mgła oraz
                        bezwietrzna pogoda.
                      </p>
                    </div>
                    <div
                      className="column right"
                      style={{
                        "-webkit-text-align": "center",
                        "text-align": "center"
                      }}
                    >
                      <img
                        className="left"
                        title
                        src="/site/assets/Uploads/author-2.png"
                        alt="author 2"
                        width="249"
                        height="250"
                      />
                      <br />
                      <br />
                      <p
                        style={{
                          "font-weight": "bold",
                          "font-style": "italic"
                        }}
                      >
                        dr Dominika Kwaśniak <br />
                        Lekarz Medycyny Estetycznej&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div
                  className="text full"
                  style={{
                    "padding-bottom": "5px"
                  }}
                >
                  <div className="p">
                    <div className="column left">
                      <p>
                        Niestety coraz więcej jest już naukowo potwierdzonych
                        doniesień o niekorzystnym wpływie smogu na organizm
                        człowieka. Może on powodować choroby płuc, alergie,
                        choroby serca, a nawet nowotwory. Ma on również
                        niekorzystny wpływ na nasza skórę. Wchodzące w skład
                        smogu związki chemiczne i pyły działają na skórę
                        toksycznie niszcząc jej barierę ochronną i niekorzystnie
                        wpływając na procesy regeneracyjne. Tak jak wpływ smogu
                        na organizm w postaci chorób obserwujemy z opóźnieniem,
                        zmiany na skórze widoczne są niemal błyskawicznie, nawet
                        po kilkudniowym przebywaniu w środowisku o dużym
                        stężeniu smogu. Toksyny w nim zawarte powodują, że cera
                        staje się szorstka i szara, a jej koloryt
                        nierównomierny. Wyglądamy starzej, jakbyśmy były
                        zmęczone.
                      </p>
                    </div>
                    <div className="column right">
                      <p>
                        Pył i kurz osadzają się na skórze, co w efekcie prowadzi
                        do zablokowania porów i zaburzenia procesów wymiany
                        gazowej i wodnej, czyli powoduje, że skóra przestaje
                        "oddychać". Trudno jest jej zatem utrzymać odpowiednie
                        nawilżenie. Osłabieniu ulega także płaszcz ochronny
                        skóry, czyli warstwa wodno-lipidowa. Skóra w efekcie
                        staje się sucha, może się nawet łuszczyć. Pozbawiona
                        mechanizmów obronnych cera jest bardziej wrażliwa na
                        czynniki drażniące. Jeśli dodatkowo narażona jest na
                        wpływ suchego powietrza, wiatr lub mróz, płaszcz
                        wodno-lipidowy ulega uszkodzeniu i w konsekwencji
                        łatwiej wnikają w skórę alergeny wywołując liczne
                        reakcje alergiczne. Skóra staje się wówczas
                        zaczerwieniona, swędząca, może pojawić się pokrzywka, a
                        nawet tendencje do egzemy. Smog powoduje także
                        nadprodukcję ebum, a w konsekwencji trądzik.
                      </p>
                    </div>
                    <div
                      style={{
                        clear: "both"
                      }}
                    >
                      &nbsp;
                    </div>
                    <div
                      style={{
                        background: "#e8eff9",
                        margin: "50px 0",
                        padding: "50px"
                      }}
                    >
                      Okazuje się także, że zmiany w składzie warstwy
                      wodno-lipidowej pod postacią zaniku lipidów mogą być
                      nieodwracalne. Niestety smog oddziaływuje niekorzystnie na
                      wszystkie, także głęboko położone warstwy skóry, a
                      zanieczyszczenia powietrza wnikając przez uszkodzoną
                      barierę ochronną skóry przyspieszają proces jej starzenia.
                      Związane jest to z wolnorodnikową teorią starzenia.
                    </div>
                    <div className="column left">
                      Wolne rodniki to małe cząstki siejące w organizmie ogromne
                      spustoszenie. W normalnych warunkach działanie
                      destrukcyjne wolnych rodników jest neutralizowane przez
                      przeciwutleniacze, czyli substancje chemiczne, które
                      możemy dostarczyć organizmowi, np. z pożywienia, kremów
                      itp. Smog zaburza równowagę pomiędzy ochronnym działaniem
                      przeciwutleniaczy, a niekorzystnym działaniem wolnych
                      rodników. Skóra narażona jest na stres oksydacyjny i
                      reaguje podobnie jak my będąc w stresie. Komórki narażone
                      na atak wolnych rodników ulegają uszkodzeniu. W skórze
                      destrukcja melanocytów, czyli komórek odpowiedzialnych za
                      koloryt powoduje powstanie przebarwień, co widoczne jest
                      najczęściej na odkrytych częściach ciała. Na twarzy cera
                      przybiera rożne odcienie, które bardzo często trudno jest
                      zamaskować nawet dobrymi kosmetykami. Smog oddziałując na
                      głębsze warstwy skóry, zaburza również procesy regeneracji
                      komórek. Uszkodzone fibroblasty nie produkują białek
                      napinających skórę, czyli elastyny i kolagenu. Skóra
                      wiotczeje i tworzą się zmarszczki.&nbsp;
                    </div>
                    <div className="column right">
                      <img
                        className="left"
                        style={{
                          position: "relative",
                          left: "-70px",
                          bottom: "-10px"
                        }}
                        title
                        src="/site/assets/Uploads/smog2.png"
                        alt="smog2"
                        width="545"
                        height="500"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="last"
                style={{
                  overflow: "visible",
                  position: "relative"
                }}
              >
                <div
                  className="text full"
                  style={{
                    position: "relative"
                  }}
                >
                  <h2
                    style={{
                      "font-size": "42px"
                    }}
                  >
                    Jak chronić się przed negatywnymi skutkami smogu?
                  </h2>
                  <p>
                    Smog uaktywnia proces starzenia komórek nawet już u osób
                    młodych. Warto więc odpowiednio wcześnie zadbać o prawidłową
                    ochronę przed smogiem. Przede wszystkim najlepiej go unikać.
                    W dni, gdy zawartość pyłów przekracza normy zdrowotne należy
                    zminimalizować ilość czasu spędzanego na powietrzu.
                    Wdychaniu zanieczyszczeń można również zapobiec używając
                    specjalnych masek antysmogowych. Aby uchronić się przed
                    niekorzystnymi procesami zachodzącymi w skórze, zaleca się
                    specjalną pielęgnację.
                  </p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <div className="p">
                    <div className="column left">
                      <ul>
                        <li>
                          Istotnym jej elementem jest oczyszczanie skóry, zawsze
                          minimum dwa razy dziennie, najlepiej zaraz po
                          przyjściu do domu.
                          <br />
                          <br />
                        </li>
                        <li>
                          Należy używać kosmetyków typu olejek w wodzie, aby
                          dokładnie zmyć wszystkie zanieczyszczenia i nadmiar
                          sebum. Idealnym preparatem myjącym będzie taki, który
                          w składzie ma dodatkowo składniki nawilżające, np.
                          kwas hialuronowy.
                          <br />
                          <br />
                        </li>
                        <li>
                          W miesiącach zimowych, kiedy w powietrzu jest
                          najwięcej smogu należy unikać intensywnego złuszczania
                          naskórka, aby dodatkowo go nie uszkadzać i nie narażać
                          na wnikanie zanieczyszczeń w głębsze warstwy skóry.
                          <br />
                          <br />
                        </li>
                        <li>
                          Następnym krokiem, chroniącym naszą skórę przed
                          szkodliwym działaniem smogu jest preparat z
                          antyoksydantami. Związki o właściwościach
                          przeciwutleniaczy stosowane w kremach to najczęściej
                          kwas hialuronowy, który zapewnia skórze odpowiednie
                          nawilżenie i aktywuje procesy regeneracyjne, witamina
                          E oraz witamina C. Neutralizują one wolne rodniki,
                          które dostają się wgłąb skóry wraz z toksynami.
                        </li>
                      </ul>
                    </div>
                    <div className="column right">
                      <ul>
                        <li>
                          Kolejnym istotnym elementem jest uszczelnienie
                          płaszcza wodno-lipidowego, dzięki zastosowaniu
                          produktów z witaminę E w składzie.
                          <br />
                          <br />
                        </li>
                        <li>
                          Warto zadbać także o ochronę przed promieniami
                          słonecznymi. Są one jednym z czynników powodujących
                          stres oksydacyjny, uszkadzając i przesuszając skórę.
                          Zwracajmy uwagę czy krem, którego używamy ma w
                          składzie filtr SPF.
                          <br />
                          <br />
                        </li>
                        <li>
                          Obcenie na rynku dostępne są kosmetyki specjalnie
                          przeznaczone do ochrony przed zanieczyszczeniami
                          środowiska. Zawierają one w swoim składzie
                          antyoksydanty, składniki nawilżające oraz filtry
                          przeciwsłoneczne.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    clear: "both"
                  }}
                />
              </section>
              <div id="social_id_3"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
